// login.js
import React, { useState } from "react";
import { useStateValue } from "./StateProvider";
import { auth, signInWithPhoneNumber, RecaptchaVerifier } from "./Firebase";
import { signOut } from "firebase/auth";
import { result } from "lodash";
import { db, collection, where, getDocs, query } from "./Firebase";

const OtpVerification = () => {
  const [phoneNumber, setPhoneNumber] = useState("+917020084004");
  const [verificationCode, setVerificationCode] = useState();
  const [verificationId, setVerificationId] = useState("");
  const [{ user }] = useStateValue();

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleSendVerificationCode = async () => {
    // Function to check if mobile number is already registered
    async function checkMobileNumberExists(phoneNumber) {
      const q = query(
        collection(db, "users"),
        where("user_mobile_number", "==", phoneNumber)
      );

      try {
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Mobile number is already registered
          alert("Mobile number is already registered");
          return true;
        } else {
          // Mobile number is not registered
          alert("Mobile number is not registered");
          return false;
        }
      } catch (error) {
        console.error("Error checking mobile number:", error);
        throw error;
      }
    }

    // Registration process
    async function registerUser(phoneNumber) {
      const mobileExists = await checkMobileNumberExists(phoneNumber);

      if (mobileExists) {
        // Handle case where mobile number is already registered
      } else {
        // Proceed with user registration
        // Create user document in Firestore or any other required actions
      }
    }

    registerUser(phoneNumber);

    console.log(
      "phoneNumber",
      phoneNumber,
      "verificationCode",
      verificationCode,
      "user",
      user
    );
    try {
      let recaptchaVerifier = await new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          // callback: (response) => {
          //   // onSignup();
          // },
          "expired-callback": () => {},
        }
      );
      let confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptchaVerifier
      );
      console.log(confirmation);
      if (confirmation) {
        console.log("Verification Code Sent");
        alert("Verification Code Sent");
      } else {
        alert("Failed to Send Verification Code");
        console.log("Failed to Send Verification Code");
      }
      setVerificationId(confirmation);
    } catch (error) {
      console.log("Error Occured: ", error);
    }
  };

  const handleSignIn = async () => {
    try {
      await verificationId
        .confirm(verificationCode)
        .then((result) => {
          alert("User Successfully Logged In..!");
          console.log("User logged in:", result.user.phoneNumber);
        })
        .catch((error) => {
          alert("OTP Mismatched..");
          console.log(error);
        });
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("User Logged Out.!");
    } catch (error) {
      console.log("Failed to Log Out !");
    }
  };

  return (
    <div>
      <h2>Sign Up with Mobile Number</h2>
      <div>
        <input
          type="text"
          placeholder="Enter your mobile number"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
        <button onClick={handleSendVerificationCode}>
          Send Verification Code
        </button>
      </div>
      <div>
        <input
          type="text"
          placeholder="Enter verification code"
          value={verificationCode}
          onChange={handleVerificationCodeChange}
        />
        <button onClick={handleSignIn}>Sign Up</button>
      </div>
      <div id="recaptcha-container"></div>
      {user && <p>Signed up user: {user.phoneNumber}</p>}
      <div>
        <button onClick={handleSignOut}>Sign Out</button>
      </div>
    </div>
  );
};

export default OtpVerification;
