import React from "react";
import "./Product.css";
import { useStateValue } from "./StateProvider";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Product({ id, title, image, price, rating }) {
  const [basket, dispatch] = useStateValue();

  const addToBasket = () => {
    // dispatch some item into data layer
    dispatch({
      type: "ADD_TO_BASKET",
      product: {
        product_id: id,
        product_name: title,
        product_image_url: image,
        product_price: price,
        product_rating: rating,
      },
    });

    toast.success(title + " Added To Basket!");
  };

  return (
    <div className="product">
      <div className="product_info">
        <Link to={`/JustAskStore/productDetails/${id}`}>
          <p className="product_title">
            <strong>{title}</strong>
          </p>
        </Link>
        <p className="product_price">
          <small>₹ </small>
          {price}
        </p>
        <div className="product_rating">
          {Array(rating)
            .fill()
            .map((_, i) => (
              <span key={`star-${i}`}>⭐</span>
            ))}
        </div>
      </div>
      <Link to={`/JustAskStore/productDetails/${id}`}>
        <img className="product_image" src={image} />
      </Link>
      <button onClick={addToBasket}>Add to Cart</button>
    </div>
  );
}

export default Product;
