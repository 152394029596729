import React, { useEffect, useState } from "react";
import "./Login.css";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BsFillShieldLockFill } from "react-icons/bs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  signInWithEmailAndPassword,
  query,
  collection,
  db,
  where,
  getDocs,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "./Firebase";
import { MetroSpinner } from "react-spinners-kit";

function Login() {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [signInButton, setSignInButton] = useState("Sign In");

  useEffect(() => {
    if (mobileNumber.length == 12) {
      setMobileNumberErrorMessage("");
      console.log(mobileNumber);
      // checkMobileNumberExists("+" + mobileNumber.substring(2, 12));
      checkMobileNumberExists("+" + mobileNumber);
    } else if (mobileNumber.length > 2) {
      setMobileNumberErrorMessage("Please Provide Valid Mobile Number");
    } else if (mobileNumber.length < 3) {
      setMobileNumber("91");
      setMobileNumberErrorMessage("");
    }
  }, [mobileNumber]);

  function backToRegistrationButton() {
    setOtp("");
    setVerificationId("");
    setShowOtp(false);
  }

  // Function to check if mobile number is already registered
  async function checkMobileNumberExists(inputNumber) {
    const q = query(
      collection(db, "users"),
      where("user_mobile_number", "==", inputNumber)
    );

    try {
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log("Mobile number is registered");
      } else {
        setMobileNumberErrorMessage(
          "Mobile Number Is Not Registered, Please Create Account To Just Ask Store"
        );
      }
    } catch (error) {
      console.error("Error checking mobile number:", error);
      setMobileNumberErrorMessage(
        "Error checking mobile number:",
        error.message
      );
      throw error;
    }
  }

  const signIn = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await verificationId
        .confirm(otp)
        .then((result) => {
          // update displayname of created user

          navigate("/JustAskStore");
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("OTP Mismatched..");
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error("Error signing up:", error);
      setErrorMessage(error.message);
    }

    // signInWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     // Signed in
    //     console.log("User signed in with email: ", userCredential.user.email);
    //     // const user = userCredential.user;
    //      navigate("/JustAskStore");
    //   })
    //   .catch((error) => {
    //     setErrorMessage(error.message);
    //     console.log(error.message);
    //   });
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    if (mobileNumber.length < 3) {
      setMobileNumberErrorMessage("Please Enter Mobile Number");
      setSignInButton("⚠️ Please Check For Corrections");
      setTimeout(() => {
        setSignInButton("Sign In");
      }, 3000);
      return;
    }
    if (mobileNumberErrorMessage) {
      setSignInButton("⚠️ Please Check For Corrections");
      setTimeout(() => {
        setSignInButton("Sign In");
      }, 3000);
      return;
    }

    setIsLoading(true);

    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = await new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            // callback: (response) => {
            //   //   // onSignup();
            // },
            "expired-callback": () => {},
          }
        );
      }
      const recaptchaVerifier = window.recaptchaVerifier;
      const formatPh = "+" + mobileNumber;
      let confirmation = await signInWithPhoneNumber(
        auth,
        formatPh,
        recaptchaVerifier
      );
      if (confirmation) {
        setErrorMessage("");
        console.log("Verification Code Sent");
        toast.success("Verification Code Sent");
        setVerificationId(confirmation);
        setIsLoading(false);
        setShowOtp(true);
      } else {
        setIsLoading(false);
        toast.error("Failed to Send Verification Code");
        console.log("Failed to Send Verification Code");
        return;
      }
    } catch (error) {
      setIsLoading(false);
      if (error.code === "auth/too-many-requests") {
        // Handle rate limiting error
        toast.error("Too many requests. Please try again later.");
        console.log("Too many requests. Please try again later.");
      } else {
        console.log("Error Occured: ", error);
        setErrorMessage(error.message);
      }
      return;
    }
  };

  return (
    <div className="login">
      <div id="recaptcha-container"></div>
      <Toaster toastOptions={{ duration: 4000 }} />
      {isLoading && (
        <div className="signUp-spinner">
          <img src={require("./images/CIRCULAR LOGO SMALL.png")} />
          <MetroSpinner size={80} color="#cd9042" />
        </div>
      )}
      <Link to="/JustAskStore/">
        <img
          className="login_logo"
          // src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2560px-Amazon_logo.svg.png"
          src={require("./images/FINAL LOGO WOB SMALL.png")}
          alt="Login Page Logo"
        />
      </Link>
      <div className="login_container">
        <h1>Sign in</h1>
        {errorMessage && <p className="Login_error_message">{errorMessage}</p>}
        {!showOtp ? (
          <>
            <form className="Login_form_container">
              <h5>Mobile Number</h5>
              <PhoneInput
                className="phoneInput"
                inputStyle={{
                  color: "inherit",
                  padding: "0 10px 0 60px",
                  backgroundColor: "white",
                  width: "100%",
                  borderRadius: "25px",
                  border: "1px solid lightgray",
                  letterSpacing: "1px",
                  fontFamily: "Amazon Ember",
                }}
                containerStyle={{
                  margin: "auto",
                  marginBottom: "10px",
                  width: "90%",
                }}
                buttonStyle={{
                  height: "100%",
                  borderRadius: "30px 0 0 30px",
                }}
                dropdownStyle={{
                  width: "265px",
                  borderRadius: "0 0 25px 25px",
                }}
                country={"in"}
                value={mobileNumber}
                onChange={setMobileNumber}
              />
              {/* <input
            type="text"
            maxLength={10}
            value={mobileNumber}
            onChange={(e) => handleMobileInput(e.target.value)}
          /> */}
              {mobileNumberErrorMessage && (
                <p className="errorMessage">{mobileNumberErrorMessage}</p>
              )}
              <button
                type="submit"
                onClick={sendOtp}
                className="login_sign_in_button"
              >
                {signInButton}
              </button>
            </form>
          </>
        ) : (
          <>
            <div className="showOtpContainer">
              <div
                onClick={backToRegistrationButton}
                style={{ textAlign: "left" }}
              >
                <KeyboardBackspaceIcon className="showOtpBackButton" />
              </div>
              <BsFillShieldLockFill size={30} />
              <h3>Please Enter OTP</h3>
              <div className="otpInputContainer">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={verificationId ? false : true}
                  autoFocus
                  className="opt-container"
                ></OtpInput>
              </div>
              <button
                type="submit"
                onClick={signIn}
                className="sign_up_register_button"
              >
                Verify OTP
              </button>
            </div>
          </>
        )}
        <p>
          By continuing, you agree to "Just Ask Store"s Conditions of Use and
          Privacy Notice.
        </p>
        <hr />
        <p>New to Just Ask Store?</p>
        <Link to="/JustAskStore/signup">
          <button className="login_register_button">
            Create Your Just Ask Store Account
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Login;
