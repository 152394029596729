import React from "react";
import "./Subtotal.css";
import CurrencyFormat from "react-currency-format";
import { useStateValue } from "./StateProvider";
import { getBasketTotal } from "./reducer";
import { useNavigate } from "react-router-dom";

function Subtotal() {
  const navigate = useNavigate();
  const [{ basket }] = useStateValue();

  return (
    <div className="subtotal">
      <CurrencyFormat
        renderText={(value) => (
          <>
            <span>
              Subtotal ({basket.length} items):&nbsp;
              <strong>{`${value}`}</strong>
            </span>
            <small className="subtotal_gift">
              <input type="checkbox" />
              This order contains a gift
            </small>
          </>
        )}
        decimalScale={2}
        fixedDecimalScale={true}
        value={getBasketTotal(basket)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"₹ "}
      />
      <button
        onClick={(e) => navigate("/JustAskStore/payment")}
        className="checkout_button"
      >
        Proceed to Checkout
      </button>
    </div>
  );
}

export default Subtotal;
