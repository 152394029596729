import React, { useEffect, useState } from "react";
import "./Home.css";
import Product from "./Product";
import UpdateToDatabase from "./UpdateToDatabase";
// import UpdateToFreeMysqlServer from "./UpdateToFreeMysqlServer";
import sampleProducts from "./TestSampleProducts";
import ProductsDivRenderer from "./ProductsDivRenderer";
import { useStateValue } from "./StateProvider";
import { db, collection, getDocs } from "./Firebase";

function Home() {
  const [{ allProducts }, dispatch] = useStateValue();

  const [errorMessage, setErrorMessage] = useState("");
  const FINALROWS = [];

  const renderProductRows = () => {
    const allProductsCopy = allProducts;
    let flag = 3;
    let indexCounter = [];
    let sentRow = [];

    for (let index = 0; index < allProducts.length; index++) {
      indexCounter.push(index);

      if (flag === 2 || allProducts.length === 2) {
        flag = 3;
        if (index !== allProducts.length - 1) {
          sentRow = indexCounter;
          FINALROWS.push(ProductsDivRenderer(allProductsCopy, sentRow));
          indexCounter = [];
        }
      } else if (flag === 5 || allProducts.length === 3) {
        flag = 1;
        if (index !== allProducts.length - 1) {
          sentRow = indexCounter;
          FINALROWS.push(ProductsDivRenderer(allProductsCopy, sentRow));
          indexCounter = [];
        }
      } else {
        flag++;
      }
    }

    sentRow = indexCounter;
    FINALROWS.push(ProductsDivRenderer(allProductsCopy, sentRow));
  };

  {
    allProducts.length > 0 && renderProductRows();
  }

  return (
    <div className="home">
      <div className="home_container">
        <img
          className="home_image"
          // src="https://images-eu.ssl-images-amazon.com/images/G/02/digital/video/merch2016/Hero/Covid19/Generic/GWBleedingHero_ENG_COVIDUPDATE__XSite_1500x600_PV_en-GB._CB428684220_.jpg"
          src={require("./images/Home_Background_Image.jpg")}
          alt="Home_Background_Image"
        />

        {errorMessage && (
          <p
            style={{
              color: "white",
              textAlign: "center",
              backgroundColor: "rgba(0,0,0,1)",
            }}
          >
            {errorMessage}
          </p>
        )}

        {FINALROWS.length > 0 && FINALROWS.map((item) => item)}

        {/* <div className="home_row">
          <Product
            id={505050}
            title={
              "Hard Coded Rakhi special Coin Pocket Wallet Mini Combo Includes : Customised nickl pen, Customised Wallet, Metal Keychain, Sindoor Chawal, Rakshabandhan card"
            }
            image={require("./images/Rakhi special hamper.webp")}
            price={399}
            // image={"https://m.media-amazon.com/images/I/819il1jGUGL._SL1500_.jpg"}
            rating={2}
          />
        </div> */}
      </div>
    </div>
  );
}

export default Home;
