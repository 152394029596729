import React from "react";
import "./Order.css";
import CheckoutProduct from "./CheckoutProduct";
import CurrencyFormat from "react-currency-format";

function Order({ order }) {
  //Code to translate the timestamp
  const timeStampToDate = (timeStamp) => {
    const newStamp = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(timeStamp);
    return newStamp;
  };

  return (
    <div className="order">
      <h2>Order</h2>
      <p>{timeStampToDate(order.data.created)}</p>
      <p className="order_id">
        <small>Payment Id: {order.id}</small>
      </p>
      {order.data.basket?.map((item) => (
        <CheckoutProduct
          key={item.product_id}
          id={item.product_id}
          title={item.product_name}
          price={item.product_price}
          rating={item.product_rating}
          image={item.product_image_url}
          hideButton
        />
      ))}
      <CurrencyFormat
        renderText={(value) => (
          <>
            <h3 className="order_total">
              Order Total:&nbsp;
              <strong>{`${value}`}</strong>
            </h3>
          </>
        )}
        decimalScale={2}
        fixedDecimalScale={true}
        value={order.data.amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"₹ "}
      />
    </div>
  );
}

export default Order;
