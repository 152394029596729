import React from "react";
import "./ProductDetails.css";
import { useStateValue } from "./StateProvider";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function ProductDetails() {
  const [{ allProducts }, dispatch] = useStateValue();
  const { productId } = useParams();

  const requestedProduct = allProducts.find(
    (product) => product.product_id === productId
  );

  if (!requestedProduct) {
    return <div>Product not found</div>;
  } else {
    const addToBasket = () => {
      dispatch({
        type: "ADD_TO_BASKET",
        product: {
          product_id: requestedProduct.product_id,
          product_name: requestedProduct.product_name,
          product_description: requestedProduct.product_description,
          product_image_url: requestedProduct.product_image_url,
          product_price: requestedProduct.product_price,
          product_rating: parseInt(requestedProduct.product_rating),
        },
      });
      toast.success(requestedProduct.product_name + " Added To Basket!");
    };

    const renderStars = (rating) => {
      const fullStars = Math.floor(rating);
      const halfStar = rating - fullStars >= 0.5;

      const stars = [];
      for (let i = 0; i < 5; i++) {
        if (i < fullStars) {
          stars.push(<span key={`full-star-${i}`}>⭐</span>);
        } else if (halfStar && i === fullStars) {
          stars.push(<span key={`half-star-${i}`}>⭐</span>);
        } else {
          stars.push(<span key={`empty-star-${i}`}>☆</span>);
        }
      }
      return stars;
    };

    return (
      <div className="product_details_component">
        <div className="product_details_container">
          <div className="product_details_info">
            <p className="product_details_title">
              <strong> {requestedProduct.product_name}</strong>
            </p>
          </div>
          <img
            src={requestedProduct.product_image_url}
            alt={requestedProduct.product_name}
          />
          <div className="product_details_info">
            <p className="product_details_title">
              {requestedProduct.product_description}
            </p>
            <p className="product_details_price">
              <small>₹ </small>
              <strong>{requestedProduct.product_price}</strong>
            </p>
            <div className="product_details_rating">
              {renderStars(requestedProduct.product_rating)}
            </div>
          </div>
          <button onClick={addToBasket}>Add to Cart</button>
        </div>
      </div>
    );
  }
}

export default ProductDetails;
