import React from "react";
import Product from "./Product";

function ProductsDivRenderer(allProductsCopy, rows) {
  let finalRow = [];
  finalRow.push(
    <div className="home_row">
      {rows.map((row) => (
        <Product
          key={allProductsCopy[row].product_id}
          id={allProductsCopy[row].product_id}
          title={allProductsCopy[row].product_name}
          price={allProductsCopy[row].product_price}
          image={allProductsCopy[row].product_image_url}
          rating={allProductsCopy[row].product_rating}
        />
      ))}
    </div>
  );
  return finalRow;
}

export default ProductsDivRenderer;
