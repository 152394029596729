import React, { useState } from "react";
import "./SignUp.css";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  createUserWithEmailAndPassword,
  db,
  collection,
  doc,
  Timestamp,
  setDoc,
  query,
  where,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updateProfile,
  updateEmail,
  updatePassword,
} from "./Firebase";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BsFillShieldLockFill } from "react-icons/bs";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { toast, Toaster } from "react-hot-toast";
import UpdateToDatabase from "./UpdateToDatabase";
import { getDoc, getDocs } from "firebase/firestore";
import { useEffect } from "react";
import { MetroSpinner } from "react-spinners-kit";
import { useStateValue } from "./StateProvider";

function SignUp() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [{ basket, user, allProducts }, dispatch] = useStateValue();
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createAccountButton, setCreateAccountButton] = useState(
    "Create Your Just Ask Store Account"
  );

  const handleFirstNameChange = (value) => {
    const rawValue = value;
    const formattedValue = rawValue.replace(/[^a-zA-Z]/g, "").toUpperCase();
    setFirstName(formattedValue);
  };
  const handleLastNameChange = (value) => {
    const rawValue = value;
    const formattedValue = rawValue.replace(/[^a-zA-Z]/g, "").toUpperCase();
    setLastName(formattedValue);
  };
  const handlePassword = (value) => {
    if (value.length < 8) {
      setPasswordErrorMessage(
        "Password Length Should Be Between 8-16 Characters"
      );
    } else {
      setPasswordErrorMessage("");
    }
    setPassword(value);
  };

  const handleEmail = (value) => {
    const rawValue = value;
    const formattedValue = rawValue.toLowerCase();
    // Basic email format validation using regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValidEmail = emailPattern.test(formattedValue);
    if (isValidEmail) {
      setEmailErrorMessage("");
    } else {
      setEmailErrorMessage("Invalid Email Address");
    }
    setEmail(formattedValue);
  };

  useEffect(() => {
    if (mobileNumber.length == 12) {
      setMobileNumberErrorMessage("");
      console.log(mobileNumber);
      // checkMobileNumberExists("+" + mobileNumber.substring(2, 12));
      checkMobileNumberExists("+" + mobileNumber);
    } else if (mobileNumber.length > 2) {
      setMobileNumberErrorMessage("Please Provide Valid Mobile Number");
    } else if (mobileNumber.length < 3) {
      setMobileNumber("91");
      setMobileNumberErrorMessage("");
    }
  }, [mobileNumber]);

  // const handleMobileInput = (inputValue) => {
  //   const input = inputValue;
  //   // Remove non-digit characters
  //   const sanitizedInput = input.replace(/\D/g, "");

  //   if (sanitizedInput.length == 10) {
  //     checkMobileNumberExists(sanitizedInput);
  //   } else {
  //     setMobileNumberErrorMessage("");
  //   }

  //   if (sanitizedInput.length <= 10) {
  //     setMobileNumber(sanitizedInput);
  //   }
  // };

  function backToRegistrationButton() {
    setOtp("");
    setVerificationId("");
    setShowOtp(false);
  }

  // Function to check if mobile number is already registered
  async function checkMobileNumberExists(inputNumber) {
    const q = query(
      collection(db, "users"),
      where("user_mobile_number", "==", inputNumber)
    );

    try {
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setMobileNumberErrorMessage("Mobile number is already registered");
      } else {
        console.log("Mobile number is not registered");
      }
    } catch (error) {
      console.error("Error checking mobile number:", error);
      setMobileNumberErrorMessage(
        "Error checking mobile number:",
        error.message
      );
      throw error;
    }
  }

  const handleSignIn = async () => {
    setIsLoading(true);
    try {
      await verificationId
        .confirm(otp)
        .then((result) => {
          // update displayname of created user
          updateProfile(result.user, {
            displayName: firstName + " " + lastName,
          })
            .then(() => {
              console.log("Displayname Updated");
            })
            .catch((error) => {
              setIsLoading(false);
              console.error(error);
            });

          const usersCollection = collection(db, "users");

          const documentId = result.user.uid; // Replace with your desired document ID

          const userDateJoined = new Date(); // Use the current date and time

          setDoc(doc(usersCollection, documentId), {
            user_first_name: firstName,
            user_last_name: lastName,
            user_mobile_number: "+" + mobileNumber,
            user_email: email,
            user_password: password,
            user_date_joined: Timestamp.fromDate(userDateJoined),
          })
            .then(() => {
              console.log("User details saved for:", result.user.displayName);
            })
            .catch((error) => {
              setIsLoading(false);
              console.error(
                "Failed to save user details for:",
                result.user.displayName,
                error
              );
            });

          navigate("/JustAskStore");
          setIsLoading(false);
          toast.success("User Successfully Signed Up..!");
          console.log("User Created:", result.user.phoneNumber);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error("OTP Mismatched..");
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error("Error signing up:", error);
      setErrorMessage(error.message);
    }
  };

  const register = async (e) => {
    e.preventDefault();
    if (mobileNumber.length < 3) {
      setMobileNumberErrorMessage("Please Enter Mobile Number");
      setCreateAccountButton("⚠️ Please Check For Corrections");
      setTimeout(() => {
        setCreateAccountButton("Create Your Just Ask Store Account");
      }, 3000);
      return;
    }
    if (mobileNumberErrorMessage || passwordErrorMessage || emailErrorMessage) {
      setCreateAccountButton("⚠️ Please Check For Corrections");
      setTimeout(() => {
        setCreateAccountButton("Create Your Just Ask Store Account");
      }, 3000);
      return;
    }

    setIsLoading(true);

    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = await new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            // callback: (response) => {
            //   //   // onSignup();
            // },
            "expired-callback": () => {},
          }
        );
      }
      const recaptchaVerifier = window.recaptchaVerifier;
      const formatPh = "+" + mobileNumber;
      let confirmation = await signInWithPhoneNumber(
        auth,
        formatPh,
        recaptchaVerifier
      );
      if (confirmation) {
        setErrorMessage("");
        console.log("Verification Code Sent");
        toast.success("Verification Code Sent");
        setVerificationId(confirmation);
        setIsLoading(false);
        setShowOtp(true);
      } else {
        setIsLoading(false);
        toast.error("Failed to Send Verification Code");
        console.log("Failed to Send Verification Code");
        return;
      }
    } catch (error) {
      setIsLoading(false);
      if (error.code === "auth/too-many-requests") {
        // Handle rate limiting error
        toast.error("Too many requests. Please try again later.");
        console.log("Too many requests. Please try again later.");
      } else {
        console.log("Error Occured: ", error);
        setErrorMessage(error.message);
      }
      return;
    }

    // createUserWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     // Successfully created new user with email and password
    //     console.log("User created with email: ", userCredential.user.email);

    //     const usersCollection = collection(db, "users");

    //     const documentId = userCredential.user.uid; // Replace with your desired document ID

    //     const userDateJoined = new Date(); // Use the current date and time

    //     setDoc(doc(usersCollection, documentId), {
    //       user_first_name: firstName,
    //       user_last_name: lastName,
    //       user_email: email,
    //       user_mobile_number: mobileNumber,
    //       user_date_joined: Timestamp.fromDate(userDateJoined),
    //     })
    //       .then(() => {
    //         console.log("User details saved for:", userCredential.user.email);
    //       })
    //       .catch((error) => {
    //         console.error(
    //           "Failed to save user details for:",
    //           userCredential.user.email,
    //           "\nError: ",
    //           error
    //         );
    //       });

    //     // const query = `
    //     //                 INSERT INTO Users (user_id, user_first_name, user_last_name, user_email, user_mobile_number, user_date_joined)
    //     //                 VALUES ('${userCredential.user.uid}', '${firstName}', '${lastName}', '${userCredential.user.email}', '${mobileNumber}', NOW())
    //     //                 `;
    //     // // VALUES ('ewww', 'first', 'last', 'namaskar@namaskar', 1234567890 )
    //     // UpdateToDatabase(query);

    //     if (userCredential) {
    //       navigate("/JustAskStore");
    //     }
    //   })
    //   .catch((error) => {
    //     setErrorMessage(error.message);
    //     console.error(error.message);
    //   });
  };

  return (
    <div className="sign_up">
      <div id="recaptcha-container"></div>
      <Toaster toastOptions={{ duration: 4000 }} />

      {isLoading && (
        <div className="signUp-spinner">
          <img src={require("./images/CIRCULAR LOGO SMALL.png")} />
          <MetroSpinner size={80} color="#cd9042" />
        </div>
      )}
      <Link to="/JustAskStore/">
        <img
          className="sign_up_logo"
          // src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2560px-Amazon_logo.svg.png"
          src={require("./images/FINAL LOGO WOB SMALL.png")}
          alt="sign up Page Logo"
        />
      </Link>
      <div className="sign_up_container">
        <h1>Create Account</h1>
        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
        {!showOtp ? (
          <>
            <form className="sign_up_form" onSubmit={register}>
              <h5>First Name</h5>
              <input
                type="text"
                value={firstName}
                // onChange={(e) => setFirstName(e.target.value)}
                onChange={(e) => handleFirstNameChange(e.target.value)}
                required
                maxLength={50}
              />
              <h5>Last Name</h5>
              <input
                type="text"
                value={lastName}
                onChange={(e) => handleLastNameChange(e.target.value)}
                required
                maxLength={50}
              />
              <h5>Mobile Number</h5>
              <PhoneInput
                className="phoneInput"
                inputStyle={{
                  color: "inherit",
                  padding: "0 10px 0 60px",
                  backgroundColor: "white",
                  width: "100%",
                  borderRadius: "25px",
                  border: "1px solid lightgray",
                  letterSpacing: "1px",
                  fontFamily: "Amazon Ember",
                }}
                containerStyle={{
                  margin: "auto",
                  marginBottom: "10px",
                  width: "90%",
                }}
                buttonStyle={{
                  height: "100%",
                  borderRadius: "30px 0 0 30px",
                }}
                dropdownStyle={{
                  width: "265px",
                  borderRadius: "0 0 25px 25px",
                }}
                country={"in"}
                value={mobileNumber}
                onChange={setMobileNumber}
              />
              {/* <input
            type="text"
            maxLength={10}
            value={mobileNumber}
            onChange={(e) => handleMobileInput(e.target.value)}
          /> */}
              {mobileNumberErrorMessage && (
                <p className="errorMessage">{mobileNumberErrorMessage}</p>
              )}
              <h5>Email</h5>
              <input
                type="text"
                value={email}
                onChange={(e) => handleEmail(e.target.value)}
                maxLength={100}
                required
              />
              {emailErrorMessage && (
                <p className="errorMessage">{emailErrorMessage}</p>
              )}
              <h5>Password</h5>
              <input
                className="sign_up_page_password"
                maxLength={16}
                type="password"
                value={password}
                onChange={(e) => handlePassword(e.target.value)}
                required
              />
              {passwordErrorMessage && (
                <p className="errorMessage">{passwordErrorMessage}</p>
              )}
              <button
                type="submit"
                // onClick={(e) => register(e)}
                className="sign_up_register_button"
              >
                {createAccountButton}
              </button>
            </form>
          </>
        ) : (
          <>
            <div className="showOtpContainer">
              <div
                onClick={backToRegistrationButton}
                style={{ textAlign: "left" }}
              >
                <KeyboardBackspaceIcon className="showOtpBackButton" />
              </div>
              <BsFillShieldLockFill size={30} />
              <h3>Please Enter OTP</h3>
              <div className="otpInputContainer">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={verificationId ? false : true}
                  autoFocus
                  className="opt-container"
                ></OtpInput>
              </div>
              <button
                type="submit"
                onClick={handleSignIn}
                className="sign_up_register_button"
              >
                Verify OTP
              </button>
            </div>
          </>
        )}
        <p>
          By creating an account or logging in, you agree to "Just Ask Store"s
          Conditions of Use and Privacy Policy.
        </p>
        <hr />
        <p>Already have an account?</p>
        <Link to="/JustAskStore/login">
          <button className="sign_up_sign_in_button">Sign In</button>
        </Link>
      </div>
    </div>
  );
}

export default SignUp;
