export const initialState = {
  allProducts: [],
  basket: [],
  user: null,
};

// Selector
export const getBasketTotal = (basket) =>
  basket?.reduce((amount, product) => {
    const productPrice = parseFloat(product.product_price);
    return parseFloat((productPrice + amount).toFixed(2));
  }, 0);

const reducer = (state, action) => {
  // console.log(action);

  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };

    case "ADD_TO_BASKET":
      return {
        ...state,
        basket: [...state.basket, action.product],
      };

    case "REMOVE_FROM_BASKET":
      const index = state.basket.findIndex(
        (product) => product.product_id === action.product_id
      );
      let newBasket = [...state.basket];
      if (index >= 0) {
        newBasket.splice(index, 1);
      } else {
        console.warn(
          `Can't Remove Product (id: ${action.product_id}) as it's not in basket!`
        );
      }
      return {
        ...state,
        basket: newBasket,
      };

    case "EMPTY_BASKET":
      return {
        ...state,
        basket: [],
      };

    case "ADD_TO_ALL_PRODUCTS":
      const isProductInAllProducts = state.allProducts.some(
        (product) => product.product_id === action.product.product_id
      );

      if (isProductInAllProducts) {
        return state;
      } else {
        return {
          ...state,
          allProducts: [...state.allProducts, action.product],
        };
      }

    case "REMOVE_ALL_PRODUCTS":
      return { ...state, allProducts: [] };

    default:
      return state;
  }
};

export default reducer;
