import React, { useEffect, useState } from "react";
import "./Orders.css";
import { collection, query, orderBy, getDocs, db } from "./Firebase";
import { useStateValue } from "./StateProvider";
import Order from "./Order";
import { MetroSpinner } from "react-spinners-kit";

function Orders() {
  const [{ basket, user }] = useStateValue();
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch the documents and their details
  const getAllOrderDocuments = async () => {
    // Construct the collection reference for the "orders" collection under the user's document
    const ordersCollectionRef = collection(db, "users", user?.uid, "orders");

    // Query to retrieve all documents within the "orders" collection
    const q = query(ordersCollectionRef, orderBy("created", "desc"));

    try {
      setIsLoading(true);
      const querySnapshot = await getDocs(q);
      const newOrders = [];
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          newOrders.push({ id: docSnap.id, data: docSnap.data() });
          console.log("Data fetched successfully..!");
        } else {
          // Unable to setOrders to empty array
          // setOrders = [];
          console.log("No such order document!");
        }
        setOrders(newOrders);
      });
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching order documents:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      getAllOrderDocuments();
    } else {
      // Unable to setOrders to empty array
      // setOrders = [];
    }
  }, [user]);

  return (
    <div className="orders">
      <>
        <h1>Your Orders</h1>
        {isLoading && (
          <div className="spinner">
            <MetroSpinner size={80} color="#cd9042" />
          </div>
        )}
        <div className="orders_order">
          {orders?.map((order) => (
            <Order key={order.id} order={order} />
          ))}
        </div>
      </>
    </div>
  );
}

export default Orders;
