import React, { useState } from "react";
import "./Payment.css";
import { useStateValue } from "./StateProvider";
import CheckoutProduct from "./CheckoutProduct";
import { getBasketTotal } from "./reducer";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import { db, doc, setDoc } from "./Firebase";
import short from "short-uuid";
import { toast } from "react-toastify";

function Payment() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [upi, setUpi] = useState("");
  const [card, setCard] = useState("");
  const navigate = useNavigate();

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      toast.error("You are offline. Failed to load payment gateway..!");

      return;
    }

    const options = {
      key: "rzp_test_t1RaPRQAxS8QJn",
      currency: "INR",
      amount: amount * 100,
      name: "Just Ask Store",
      description: "Testing Description",
      handler: function (response) {
        if (response.razorpay_payment_id) {
          const orderData = {
            basket: basket,
            amount: getBasketTotal(basket),
            created: Date.now(),
          };
          try {
            const paymentIntentId = short.generate();

            // Construct the reference to the specific order document
            const orderRef = doc(
              db,
              `users/${user?.uid}/orders/${response.razorpay_payment_id}`
            );

            // Use the Firestore SDK to set the order data in the specified document
            setDoc(orderRef, orderData)
              .then(() => {
                console.log("Order successfully added to Firestore!");
                toast.success("Order successfully Placed");
              })
              .catch((error) => {
                console.error("Error adding order to Firestore:", error);
                toast.error("Error placing the order", error);
              });

            dispatch({ type: "EMPTY_BASKET" });
            navigate("/JustAskStore/orders");
          } catch (error) {
            console.error("Error connecting to Firestore:", error);
            toast.error("Error connecting to database", error);
          }
        }
      },
      prefill: { name: user.displayName },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className="payment">
      <div className="payment_container">
        <h2>Checkout Items : {basket?.length} </h2>
        <div className="payment_section">
          <div className="payment_title">
            <h3>Delivery Address</h3>
          </div>
          <div className="payment_address">
            <p>{user?.email}</p>
            <p>0509 A Wing, Matoshree, 5th Lane, Near Rankala Lake,</p>
            <p>Kolhapur, MH, 416012</p>
          </div>
        </div>
        <div className="payment_section">
          <div className="payment_title">
            <h3>Review items and delivery</h3>
          </div>
          <div className="payment_items">
            {basket.map((item) => (
              <CheckoutProduct
                key={item.product_id}
                id={item.product_id}
                title={item.product_name}
                price={item.product_price}
                rating={item.product_rating}
                image={item.product_image_url}
              />
            ))}
          </div>
        </div>
        <div className="payment_section">
          <div className="payment_title">
            <h3>Order Total</h3>
          </div>
          <div className="payment_details">
            <div className="payment_form">
              <div className="payment_price">
                <CurrencyFormat
                  renderText={(value) => (
                    <>
                      <h3>
                        <strong>{`${value}`}</strong>
                      </h3>
                    </>
                  )}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={getBasketTotal(basket)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₹ "}
                />
              </div>
              <button
                className="paymentBuyNowButton"
                onClick={() => displayRazorpay(getBasketTotal(basket))}
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;
