// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getFirestore,
  collection,
  query,
  orderBy,
  getDocs,
  setDoc,
  doc,
  addDoc,
  arrayUnion,
  where,
  Timestamp,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updateProfile,
  updateEmail,
  updatePassword,
} from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCj1Ezh6xhT16onNUGvyeuEScwCqyLoMzI",
  authDomain: "saurabh-salokhe-fullstack.firebaseapp.com",
  projectId: "saurabh-salokhe-fullstack",
  storageBucket: "saurabh-salokhe-fullstack.appspot.com",
  messagingSenderId: "1004216349415",
  appId: "1:1004216349415:web:105c1ae042000d87ff2842",
  measurementId: "G-9V1B52MYFY",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Create instances of Firestore and Auth
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

// Export the instances
export {
  db,
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  collection,
  query,
  orderBy,
  getDocs,
  setDoc,
  doc,
  addDoc,
  arrayUnion,
  where,
  Timestamp,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updateProfile,
  updateEmail,
  updatePassword,
};
