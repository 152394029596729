import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { auth } from "./Firebase";
import "./ExtraHeader.css";
import ReactDOM from "react-dom";
import Home from "./Home";
// import _ from "lodash";

function Header() {
  const [{ basket, user, allProducts }, dispatch] = useStateValue();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false); // New state
  const searchRef = useRef(); // Ref for the search div
  const searchInputRef = useRef(); // Ref for the search input
  const searchIconRef = useRef(); // Ref for the search icon

  // using firebase database
  const handleSearch = async (searchQuery) => {
    searchProducts(allProducts, searchQuery);
    function searchProducts(allProducts, searchQuery) {
      if (searchQuery.length > 0) {
        if (!searchQuery.trim()) {
          setSearchResults([]);
        }

        const lowercasedSearchQuery = searchQuery.toLowerCase();

        const foundSearchResults = allProducts.filter(
          (product) =>
            product.product_name
              .toLowerCase()
              .includes(lowercasedSearchQuery) ||
            product.product_description
              .toLowerCase()
              .includes(lowercasedSearchQuery)
        );
        setSearchResults(foundSearchResults);
      } else {
        setSearchResults([]);
      }
    }
  };

  // Debounced version of the handleSearch function
  // const debouncedHandleSearch = _.debounce(handleSearch, 300);

  // Throttled version of the handleSearch function
  // const throttledHandleSearch = _.throttle(handleSearch, 300);

  useEffect(() => {
    handleSearch(searchQuery); // Initial search
  }, [searchQuery]); // Trigger on searchQuery change

  // useEffect(() => {
  //   if (showSearchResults) {
  //     // debouncedHandleSearch(searchQuery);
  //     throttledHandleSearch(searchQuery);
  //   }
  // }, [
  //   searchQuery,
  //   // debouncedHandleSearch,
  //   throttledHandleSearch,
  //   showSearchResults,
  // ]);

  // Add event listener to close results when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const refs = [searchInputRef.current, searchIconRef.current];
      if (refs.every((ref) => ref && !ref.contains(event.target))) {
        setShowSearchResults(false);
      }
    };

    if (searchInputRef.current && searchIconRef.current) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      if (searchInputRef.current && searchIconRef.current) {
        document.removeEventListener("click", handleClickOutside);
      }
    };
  }, []);

  useEffect(() => {
    const handleInputFocus = () => {
      setShowSearchResults(true);
    };

    const handleIconClick = () => {
      setShowSearchResults(true);
      searchInputRef.current.focus();
    };

    if (searchInputRef.current && searchIconRef.current) {
      searchInputRef.current.addEventListener("focus", handleInputFocus);
      searchIconRef.current.addEventListener("click", handleIconClick);
    }

    return () => {
      if (searchInputRef.current && searchIconRef.current) {
        searchInputRef.current.removeEventListener("focus", handleInputFocus);
        searchIconRef.current.removeEventListener("click", handleIconClick);
      }
    };
  }, []);

  // using mysql database
  // const handleSearch = async () => {
  //   try {
  //     // if (searchQuery.length > 3) {
  //     const query = `SELECT * FROM Products WHERE product_name LIKE '%${searchQuery}%' OR product_description LIKE '%${searchQuery}%';`;
  //     UpdateToDatabase(query)
  //       .then((data) => {
  //         setSearchResults(data);
  //         console.log(data);
  //       })
  //       .catch((error) => {
  //         // Handle the error
  //         // console.error("Error:", error);
  //       });
  //     // }
  //   } catch (error) {
  //     console.error("Error fetching search results:", error);
  //   }
  // };

  const handleAuthentication = () => {
    if (user) {
      auth.signOut();
    }
  };

  return (
    <div className="header">
      <Link to="/JustAskStore">
        <img
          className="header_logo"
          src={require("./images/HORIZONTAL-LOGO.png")}
        />
      </Link>
      <div className="header_search">
        <input
          className="header_search_input"
          type="text"
          value={searchQuery}
          // onClick={handleSearch}
          onChange={(e) => setSearchQuery(e.target.value)}
          ref={searchInputRef} // Assign the ref to the input
        />
        <SearchIcon
          className="header_search_icon"
          // onClick={handleSearch}
          ref={searchIconRef} // Assign the ref to the icon
        />
        {showSearchResults &&
          searchResults.length > 0 &&
          searchQuery.length > 0 && (
            <div className="header_search_results" ref={searchRef}>
              <ul>
                {searchResults.map((result) => (
                  <Link
                    to={`/JustAskStore/productDetails/${result.product_id}`}
                  >
                    <li key={result.product_id}>
                      <div className="search_result_item_container">
                        <div className="search_image_div">
                          <img
                            className="search_result_image"
                            src={result.product_image_url}
                            alt="search_result_image"
                          />
                        </div>
                        <div className="search_result_info_div">
                          <h3>{result.product_name}</h3>
                          <p>{result.product_description}</p>
                          <p>Price: ₹{result.product_price}</p>
                        </div>
                      </div>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          )}
      </div>
      <div className="header_nav">
        <Link to={!user ? "/JustAskStore/login" : "/JustAskStore/"}>
          <div onClick={handleAuthentication} className="header_option">
            <span className="header_option_line_one">
              {user
                ? user.displayName
                  ? user.displayName
                  : user.email
                  ? user.email
                  : user.phoneNumber
                : "Hello Guest"}
            </span>
            <span className="header_option_line_two">
              {user ? "Sign Out" : "Sign In"}
            </span>
          </div>
        </Link>
        <Link to={user ? "/JustAskStore/orders" : "/JustAskStore/login"}>
          <div className="header_option">
            <span className="header_option_line_one">Returns</span>
            <span className="header_option_line_two">& Orders</span>
          </div>
        </Link>
        <Link to={user ? "/JustAskStore/" : "/JustAskStore/login"}>
          <div className="header_option">
            <span className="header_option_line_one">Your</span>
            <span className="header_option_line_two">Favourites</span>
          </div>
        </Link>
        <Link to={user ? "/JustAskStore/checkout" : "/JustAskStore/login"}>
          <div className="header_option_basket">
            <ShoppingCartIcon />
            <span className="header_option_line_two header_basket_count">
              {basket?.length}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Header;
