import React, { useEffect, useState } from "react";
import "./AddProduct.css";
import { db, collection, addDoc, getDocs, arrayUnion, doc } from "./Firebase";

const AddProduct = () => {
  useEffect(() => {
    fetchCategories();
  }, []);
  const [formData, setFormData] = useState({
    category: "",
    name: "",
    description: "",
    price: "",
    imageUrl: "",
    rating: "",
    stockQuantity: "",
  });

  const [fetchedCategories, setFetchedCategories] = useState([]);

  async function fetchCategories() {
    const categoriesCollectionRef = collection(db, "categories");

    getDocs(categoriesCollectionRef).then((querySnapshot) => {
      const categoryList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().category_name,
      }));

      // Sort the category list by name before setting in state
      const sortedCategories = categoryList.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      // Add a default "Select" option at the beginning with an empty id
      const selectOption = { id: "", name: "Select" };
      setFetchedCategories([selectOption, ...sortedCategories]);
    });
  }

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [productId, setProductId] = useState("");

  const MAX_NAME_LENGTH = 100; // Set the maximum name length
  const nameCharactersLeft = MAX_NAME_LENGTH - formData.name.length;
  const MAX_DESCRIPTION_LENGTH = 250; // Set the maximum name length
  const descriptionCharactersLeft =
    MAX_DESCRIPTION_LENGTH - formData.description.length;

  // Function to process the image URL
  function processImageUrl(url) {
    let newString = url.replace("file/d/", "uc?id=");
    let finalString = newString.replace("/view?usp=drive_link", "");
    return finalString;
  }

  // Function to capitalize each word in a string
  function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "category":
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        break;

      case "name":
        // Transform the input value as per the provided logic
        const filteredName = value
          .replace(/\n/g, " ")
          .replace(/_+/g, "")
          .replace(/ +/g, " ");

        // Validate the filtered value to allow only alphabets and spaces
        // if (!/^[a-zA-Z\s]*$/.test(filteredName)) {
        //   return;
        // }

        setFormData((prevData) => ({
          ...prevData,
          [name]: filteredName,
        }));
        break;

      case "description":
        // Transform the input value as per the provided logic
        const filteredDescription = value
          .replace(/\n/g, " ")
          .replace(/_+/g, "")
          .replace(/ +/g, " ");

        setFormData((prevData) => ({
          ...prevData,
          [name]: filteredDescription,
        }));
        break;

      case "price":
        // Transform the input value as per the provided logic
        const filteredPrice = value.replace(/[^0-9.]/g, "");

        setFormData((prevData) => ({
          ...prevData,
          [name]: filteredPrice,
        }));
        break;

      case "imageUrl":
        // Handle category field
        setFormData((prevData) => ({
          ...prevData,
          [name]: value, // Update category without transformation/validation
        }));
        break;

      case "rating":
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        break;

      case "stockQuantity":
        // Transform the input value as per the provided logic
        const filteredStockQuantity = value.replace(/[^0-9]/g, "");

        setFormData((prevData) => ({
          ...prevData,
          [name]: filteredStockQuantity,
        }));
        break;

      default:
        break;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formData.category) {
      alert("Please select product category.");
      return;
    }

    if (!formData.name) {
      alert("Please enter product name.");
      return;
    }

    if (!formData.description) {
      alert("Please enter product description.");
      return;
    }

    if (!formData.price) {
      alert("Please enter product price.");
      return;
    }

    if (!formData.imageUrl) {
      alert("Please provide product image url.");
      return;
    }

    if (!formData.rating) {
      alert("Please select product rating.");
      return;
    }

    if (!formData.stockQuantity) {
      alert("Please enter product stock quantity.");
      return;
    }
    const category = [];
    category.push(formData.category);
    const productName = capitalizeEachWord(formData.name.trim());
    const productDescription = capitalizeEachWord(formData.description.trim());
    const productPrice = parseFloat(formData.price).toFixed(2);
    const productImage = processImageUrl(formData.imageUrl);
    const productRating = formData.rating;
    const productStockQuantity = parseInt(formData.stockQuantity);

    const productData = {
      product_name: productName,
      product_description: productDescription,
      product_price: productPrice,
      product_image_url: productImage,
      product_rating: productRating,
      product_stock_quantity: productStockQuantity,
      category_refs: category,
    };

    const productsCollection = collection(db, "products");
    // Add the new product document
    addDoc(productsCollection, productData)
      .then((docRef) => {
        console.log("Product document added with ID: ", docRef.id);
        setFormSubmitted(true);
        setProductId(docRef.id);
        setTimeout(() => {
          setFormSubmitted(false);
        }, 5000);
      })
      .catch((error) => {
        console.error("Error adding product document: ", error);
      });

    setFormData({
      category: "",
      name: "",
      description: "",
      price: "",
      imageUrl: "",
      rating: "",
      stockQuantity: "",
    });
  };

  return (
    <div>
      <div className="add_products">
        <div className="add_product_form_container">
          <h1>Add Product To Store</h1>
          <form className="add_product_form" onSubmit={handleSubmit}>
            <h3>Product Category</h3>
            <div className="add_product_categories">
              <select
                name="category"
                value={formData.category}
                onChange={handleInputChange}
              >
                {fetchedCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="add_product_inputfields">
              <h3>Product Name</h3>
              <input
                type="text"
                maxLength={MAX_NAME_LENGTH}
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <br />
              <p>Characters left: {nameCharactersLeft}</p>
              <h3>Product Description</h3>
              <textarea
                className="add_product_description_textarea"
                name="description"
                maxLength={MAX_DESCRIPTION_LENGTH}
                rows="4"
                cols="50"
                value={formData.description}
                onChange={handleInputChange}
              ></textarea>
              <br />
              <p>Characters left: {descriptionCharactersLeft}</p>
              <h3>Product Price</h3>
              <input
                type="text"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
              />
              <br />
              <h3>Product Image Url</h3>
              {formData.imageUrl && (
                <div className="add_product_fetched_url_image_container">
                  <img
                    className="add_product_fetched_url_image"
                    src={processImageUrl(formData.imageUrl)}
                    alt="image_fetched_from_url"
                  />
                </div>
              )}
              <input
                type="url"
                name="imageUrl"
                value={formData.imageUrl}
                onChange={handleInputChange}
              />
              <br />
              <h3>Product Rating</h3>
              <div className="add_product_rating_radio_buttons">
                <input
                  type="radio"
                  name="rating"
                  value="1"
                  checked={formData.rating === "1"}
                  onChange={handleInputChange}
                />
                <label>⭐</label>
                <input
                  type="radio"
                  name="rating"
                  value="2"
                  checked={formData.rating === "2"}
                  onChange={handleInputChange}
                />
                <label>⭐⭐</label>
                <input
                  type="radio"
                  name="rating"
                  value="3"
                  checked={formData.rating === "3"}
                  onChange={handleInputChange}
                />
                <label>⭐⭐⭐</label>
                <input
                  type="radio"
                  name="rating"
                  value="4"
                  checked={formData.rating === "4"}
                  onChange={handleInputChange}
                />
                <label>⭐⭐⭐⭐</label>
                <input
                  type="radio"
                  name="rating"
                  value="5"
                  checked={formData.rating === "5"}
                  onChange={handleInputChange}
                />
                <label>⭐⭐⭐⭐⭐</label>
              </div>
              <h3>Product Stock Quantity</h3>
              <input
                type="text"
                name="stockQuantity"
                value={formData.stockQuantity}
                onChange={handleInputChange}
              />
              <br />
            </div>
            {formSubmitted && (
              <p style={{ color: "green" }}>
                Prodect Added Successfully!
                <br />
                Product ID: {productId}
              </p>
            )}
            <button className="add_product_submit_button" type="submit">
              SUBMIT
            </button>
          </form>
          <div className="add_product_output_container">
            <br />
          </div>

          <div className="add_product_modal_container">
            <div className="add_product_modal_content">
              <span className="add_product_modal_close_btn">&times;</span>
              <pre></pre>
              <button className="add_product_upload_button">
                Upload To Database
              </button>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
