import "./App.css";
import Checkout from "./Checkout";
import Header from "./Header";
import Home from "./Home.js";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from "./Login";
import Payment from "./Payment";
import { useEffect } from "react";
import { auth, collection, db, getDocs } from "./Firebase";
import { useStateValue } from "./StateProvider";
import Orders from "./Orders";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for styling
import SignUp from "./SignUp";
import AddProduct from "./AddProduct";
import ProductDetails from "./ProductDetails";
import OtpVerification from "./OtpVerification";
import { Margin } from "@mui/icons-material";

function App() {
  const [{}, dispatch] = useStateValue();

  // const updateDetails = async () => {
  //   // update displayname of created user
  //   updateProfile(auth.currentUser, {
  //     displayName: firstName + " " + lastName,
  //   })
  //     .then(() => {
  //       console.log("Displayname Updated");
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.log(error);
  //     });

  //   // update email id of created user
  //   updateEmail(auth.currentUser, email)
  //     .then(() => {
  //       console.log("Email Updated");
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.log(error);
  //     });

  //   // update password of created user
  //   updatePassword(auth.currentUser, password)
  //     .then(() => {
  //       console.log("Password Updated");
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   if (user) {
  //     updateDetails();
  //   }
  // }, []);

  useEffect(() => {
    //code
    auth.onAuthStateChanged((userCredential) => {
      if (userCredential) {
        //user logged in
        console.log(
          userCredential.displayName
            ? "The User is :" + userCredential.displayName
            : userCredential.email
            ? "The User is :" + userCredential.email
            : "The User is :" + userCredential.phoneNumber
        );
        dispatch({
          type: "SET_USER",
          user: userCredential,
        });
      } else {
        //user logged out
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });
  }, []);

  useEffect(() => {
    // Fetch products when the component mounts
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    dispatch({ type: "REMOVE_ALL_PRODUCTS" });

    try {
      const productsCollection = collection(db, "products");
      const querySnapshot = await getDocs(productsCollection);

      querySnapshot.docs.forEach((docSnap) =>
        dispatch({
          type: "ADD_TO_ALL_PRODUCTS",
          product: {
            product_id: docSnap.id,
            product_name: docSnap.data().product_name,
            product_description: docSnap.data().product_description,
            product_price: parseFloat(docSnap.data().product_price).toFixed(2),
            product_image_url: docSnap.data().product_image_url,
            product_rating: parseInt(docSnap.data().product_rating),
          },
        })
      );
    } catch (error) {
      console.error("Error fetching products:", error);
    }

    // // Currently Working Code
    // try {
    //   const productsCollection = collection(db, "products");
    //   const querySnapshot = await getDocs(productsCollection);
    //   const fetchedProducts = [];

    //   querySnapshot.docs.forEach((docSnap) =>
    //     fetchedProducts.push({
    //       product_id: docSnap.id,
    //       product_name: docSnap.data().product_name,
    //       product_description: docSnap.data().product_description,
    //       product_price: docSnap.data().product_price,
    //       product_image_url: docSnap.data().product_image_url,
    //       product_rating: docSnap.data().product_rating,
    //     })
    //   );

    //   setProducts(fetchedProducts);
    //   setProductsToSearch(fetchedProducts);
    //   console.log("Products fetched successfully:", products);
    //   console.log("Products fetched successfully:", products);
    // } catch (error) {
    //   console.error("Error fetching products:", error);
    // }

    // const productsCollectionRef = collection(db, "products");
    // try {
    //   const querySnapshot = await getDocs(productsCollectionRef);
    //   const fetchedProducts = [];
    //   querySnapshot.docs.map((docSnap) =>
    //     fetchedProducts.push({
    //       id: docSnap.id,
    //       product_name: docSnap.data().product_name,
    //       product_description: docSnap.data().product_description,
    //       product_price: docSnap.data().product_price,
    //       product_image_url: docSnap.data().product_image_url,
    //       product_rating: docSnap.data().product_rating,
    //     })
    //   );
    //   setProducts(fetchedProducts);
    //   console.log("Products fetched successfully:", JSON.stringify(products));
    // } catch (error) {
    //   console.error("Error fetching orders:", error);
    // }

    // finally {
    //   setIsLoading(false);
    // }

    // try {
    //   const query = `SELECT * FROM Products;`;
    //   //
    //   UpdateToDatabase(query)
    //     .then((data) => {
    //       setProducts(data);
    //       console.log(data);
    //     })
    //     .catch((error) => {
    //       // console.error("Error:", error);
    //       setErrorMessage("Error: " + error);
    //     });
    // } catch (error) {
    //   console.error("Error fetching search results:", error);
    //   setErrorMessage("Error: " + error);
    // }
  };

  return (
    <Router>
      <div className="app">
        <Routes>
          {/* Route to Home Page */}
          <Route path="/JustAskStore" element={[<Header />, <Home />]} exact />

          {/* Route to Checkout Page */}
          <Route
            path="/JustAskStore/checkout"
            element={[<Header />, <Checkout />]}
            exact
          />

          {/* Route to Payment Page */}
          <Route
            path="/JustAskStore/payment"
            element={[<Header />, <Payment />]}
            exact
          />

          {/* Route to Orders Page */}
          <Route
            path="/JustAskStore/orders"
            element={[<Header />, <Orders />]}
            exact
          />

          {/* Route to Login Page */}
          <Route path="/JustAskStore/login" element={[<Login />]} exact />

          {/* Route to Sign Up Page */}
          <Route path="/JustAskStore/signup" element={[<SignUp />]} exact />

          {/* Route to Product Details Page */}
          <Route
            path="/JustAskStore/productDetails/:productId"
            element={[<Header />, <ProductDetails />]}
            exact
          />

          {/* Route to Add Product Page */}
          <Route
            path="/JustAskStore/addproduct"
            element={[<Header />, <AddProduct />]}
            exact
          />

          {/* Route to OTP Verification Page */}
          <Route
            path="/JustAskStore/otpVerification"
            element={[<Header />, <OtpVerification />]}
            exact
          />

          {/* Route to catch all other urls */}
          <Route
            exact
            path="/JustAskStore/*"
            element={[
              <Header />,
              <h1>
                Error 404
                <br /> page not found,
                <br />
                please check the link..!
              </h1>,
            ]}
          />

          {/* Route to portfolio home page */}
          <Route
            path="/"
            element={[
              <div
                className="portfolio_background_image"
                style={{
                  display: "flex",
                  height: "100vh",
                  fontFamily: "Amazon Ember",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255,255,255,0.2)",
                    color: "white",
                    margin: "auto",
                    width: "500px",
                    borderRadius: "15px",
                    border: "1px solid white",
                    padding: "50px",
                    boxShadow: "0 0 15px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <h1>Saurabh Salokhe</h1>
                  <br />
                  <h3>
                    Welcomes you to portfolio..!
                    <br /> Portfolio is under construction... till then...
                    <br />
                    Please visit Just Ask Store
                  </h3>
                  <Link to={"/JustAskStore"}>
                    <div>
                      <button
                        className="showOtpBackButton"
                        style={{
                          padding: "10px 20px",
                          fontSize: "1.2em",
                          boxShadow: "0 1px 4px rgba(138, 21, 42, 0.3)",
                          cursor: "pointer",
                          fontFamily: "inherit",
                        }}
                      >
                        Go to Just Ask Store
                      </button>
                    </div>
                  </Link>
                  <h3>To download CV please click below</h3>
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <Link
                      to={"/Saurabh Salokhe CV.pdf"}
                      target="_blank"
                      download
                    >
                      <button
                        className="showOtpBackButton"
                        style={{
                          padding: "10px 20px",
                          fontSize: "1.2em",
                          boxShadow: "0 1px 4px rgba(138, 21, 42, 0.3)",
                          cursor: "pointer",
                          fontFamily: "inherit",
                        }}
                      >
                        Download CV
                      </button>
                    </Link>
                  </div>
                </div>
              </div>,
            ]}
            exact
          />

          {/* Route to catch all other portfolio urls */}
          <Route
            path="/*"
            element={[
              <div
                className="portfolio_background_image"
                style={{
                  display: "flex",
                  height: "100vh",
                  fontFamily: "Amazon Ember",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    backgroundColor: "rgba(255,255,255,0.2)",
                    color: "white",
                    margin: "auto",
                    width: "500px",
                    borderRadius: "15px",
                    border: "1px solid white",
                    padding: "50px",
                    boxShadow: "0 0 15px 10px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <h1>Saurabh Salokhe</h1>
                  <br />
                  <h3>
                    Oops..! Looks like you entered a wrong link or trying to
                    access different projects, Glimpse & Cashpro project's pages
                    are under construction
                    <br /> Note: Portfolio is under construction...
                    <br />
                    <br />
                    To visit Just Ask Store please click the button below
                  </h3>
                  <Link to={"/JustAskStore"}>
                    <div>
                      <button
                        className="showOtpBackButton"
                        style={{
                          padding: "10px 20px",
                          fontSize: "1.2em",
                          boxShadow: "0 1px 4px rgba(138, 21, 42, 0.3)",
                          cursor: "pointer",
                          fontFamily: "inherit",
                        }}
                      >
                        Go to Just Ask Store
                      </button>
                    </div>
                  </Link>
                  <h3>To download CV please click below</h3>
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <Link
                      to={"/Saurabh Salokhe CV.pdf"}
                      target="_blank"
                      download
                    >
                      <button
                        className="showOtpBackButton"
                        style={{
                          padding: "10px 20px",
                          fontSize: "1.2em",
                          boxShadow: "0 1px 4px rgba(138, 21, 42, 0.3)",
                          cursor: "pointer",
                          fontFamily: "inherit",
                        }}
                      >
                        Download CV
                      </button>
                    </Link>
                  </div>
                </div>
              </div>,
            ]}
            exact
          />
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
