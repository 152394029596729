import React from "react";
import "./Checkout.css";
import Subtotal from "./Subtotal";
import CheckoutProduct from "./CheckoutProduct";
import { useStateValue } from "./StateProvider";

function Checkout() {
  const [{ basket, user }, dispatch] = useStateValue();

  return (
    <div className="checkout">
      <div className="checkout_left">
        <img
          className="checkout_ad"
          src={require("./images/Checkout_Ad.jpg")}
          alt=""
        />
        <div>
          <h3>
            Hello,{" "}
            {user
              ? user.displayName
                ? user.displayName
                : user.email
                ? user.email
                : user.phoneNumber
              : "Guest"}
          </h3>
          <h2 className="checkout_title">Your Shopping Cart</h2>
          {basket.map((item) => (
            <CheckoutProduct
              key={item.product_id}
              id={item.product_id}
              title={item.product_name}
              price={item.product_price}
              rating={item.product_rating}
              image={item.product_image_url}
            />
          ))}
        </div>
      </div>
      <div className="checkout_right">
        <Subtotal />
      </div>
    </div>
  );
}

export default Checkout;
