import React from "react";
import "./CheckoutProduct.css";
import { useStateValue } from "./StateProvider";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function CheckoutProduct({ id, title, price, rating, image, hideButton }) {
  const [{ basket }, dispatch] = useStateValue();
  const removeFromBasket = () => {
    dispatch({
      type: "REMOVE_FROM_BASKET",
      product_id: id,
    });
  };

  return (
    <div className="checkoutProduct">
      <div className="checkoutProduct_image_container">
        <Link to={`/JustAskStore/productDetails/${id}`}>
          <img className="checkoutProduct_image" src={image} />
        </Link>
      </div>
      <div className="checkoutProduct_info">
        <Link to={`/JustAskStore/productDetails/${id}`}>
          <p className="checkoutProduct_title">{title}</p>
        </Link>
        <p className="checkoutProduct_price">
          <small>₹ </small>
          <strong>{price}</strong>
        </p>
        <div className="checkoutProduct_rating">
          {Array(rating)
            .fill()
            .map((_, i) => (
              <span>⭐</span>
            ))}
        </div>
        {!hideButton && (
          <button onClick={removeFromBasket}>Remove from Basket</button>
        )}
      </div>
    </div>
  );
}

export default CheckoutProduct;
